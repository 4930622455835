/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { UserRegistrationStatus } from '@api/index';
import { UserAccountInfo } from '@api/model/userAccountInfo';
import { UntilDestroy } from '@core/classes/untilDestroy.class';
import { MenuDesktopConfirmComponent, MenuMobileComponent } from '@core/components/menu/menu.component';
import { SelectedAccount } from '@core/components/user-account-selector/user-account-selector.component';
import { NOTICE_TYPE } from '@core/constants';
import { FI_ALREADY_LINKED_CODE } from '@core/constants/error-code';
import { UtilService } from '@core/services/util.service';
import { isMobile } from '@core/utils';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { updateConsentGrant } from '@store/checkout/checkout.actions';
import { selectConsent, selectConsentGrantPayload } from '@store/checkout/checkout.selectors';
import { ILinkedFI, PaymentMethodCheckStatus } from '@store/common/user.interface';
import { setOIDCCode } from '@store/core/core.actions';
import { selectOIDCCode, selectOIDCCodeDetail } from '@store/core/core.selectors';
import { UserActions } from '@store/user/user.actions';
import {
  selectDefaultAccountId,
  selectDefaultFiId,
  selectDefaultSavingAccountId,
  selectUserProfile,
  selectUserProfileAccounts,
  selectUserProfileEmail,
  selectUserProfileLinkedFIs,
  selectUserType,
} from '@store/user/user.selectors';
import { Observable, debounceTime, take, takeUntil, withLatestFrom } from 'rxjs';

export abstract class PaymentMethodClass extends UntilDestroy {
  isGuestCheckout: boolean = false;
  continueToReview: boolean = false;
  isRegistered: boolean = false;
  isOrderReview: boolean = false;
  isReturningUser: boolean = false;

  selectedAccount: string = '';
  accounts$: Observable<Array<UserAccountInfo>> = this.store.select(selectUserProfileAccounts);
  email$: Observable<string> = this.store.select(selectUserProfileEmail);
  linkedFIs$: Observable<Array<ILinkedFI>> = this.store.select(selectUserProfileLinkedFIs);
  defaultFiId$: Observable<string> = this.store.select(selectDefaultFiId);
  defaultAccountId$: Observable<string> = this.store.select(selectDefaultAccountId);
  defaultSavingAccountId$: Observable<string> = this.store.select(selectDefaultSavingAccountId);

  merchantSupportedAccountTypes: string[] = [];
  noticeType = NOTICE_TYPE;
  errorMessage!: string;
  fullErrorMessage!: string;
  isDismissible: boolean = false;
  merchantName!: string;
  isMobile = isMobile();
  errorKey!: string;
  oidcDetails$ = this.store.select(selectOIDCCodeDetail('checkout'));
  fiAlreadyLinkedCode = FI_ALREADY_LINKED_CODE;
  paymentCheckStatus!: PaymentMethodCheckStatus;

  protected constructor(
    private readonly store: Store,
    private readonly translateService: TranslateService,
    private _bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    public utilService: UtilService
  ) {
    super();
    // to do default account check from userProfile instead of consent grant
    this.store
      .select(selectConsentGrantPayload)
      .pipe(
        withLatestFrom(
          this.accounts$,
          this.store.select(selectConsent),
          this.store.select(selectDefaultAccountId),
          this.store.select(selectUserType),
          this.store.select(selectUserProfile)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(([consentGrantPayload, accounts, consent, defaultAccountRef, userType, userProfile]) => {
        this.isRegistered = userType === UserRegistrationStatus.Registered;
        const selectedAccountId = consentGrantPayload?.fi_account_ref || '';
        const selectedFiId = consentGrantPayload.selected_fi_id;
        const selectedFiUserId = consentGrantPayload.fi_user_id;
        const selectedAccount = accounts.find((acct) => acct.fi_account_ref === consentGrantPayload?.fi_account_ref);
        this.merchantName = consent?.data?.store.name || '';
        if (selectedAccount && !selectedAccount.disable_reason && selectedAccountId && selectedFiId) {
          this.selectedAccount = JSON.stringify({ selectedAccountId, selectedFiId, selectedFiUserId });
        }
        if (accounts.every((a) => !!a.disable_reason)) {
          this.fullErrorMessage = this.translateService.instant('COMMON.NO_PAYMENT_METHODS_AVAILABLE_FULL', {
            supportedPayments:
              consent?.data?.merchant_supported_payment_methods?.map((pm) =>
                this.translateService.instant(`COMMON.ACCOUNT_TYPE.${pm}`)
              ) || [],
          });
          this.errorMessage = this.translateService.instant('COMMON.NO_PAYMENT_METHODS_AVAILABLE');
          //this.noPaymentMethodsAvailable = true;
        } else if (
          selectedAccount &&
          !consent?.data?.merchant_supported_payment_methods?.includes(selectedAccount.account_type)
        ) {
          // merchant not supported payment method
          const paymentMethodTranslatedValue: Array<string> = this.utilService.getPaymentTypeTranslatedValues([
            selectedAccount.account_type,
          ]);
          this.errorMessage = this.translateService.instant('COMMON.DEFAULT_PAYMENT_METHOD_NOT_SUPPORTED_ERROR', {
            merchant: consent?.data?.store.name,
            paymentMethod: paymentMethodTranslatedValue.toString(),
          });
        } else if (!selectedAccount) {
          // default not available or default disabled or not in linked fis list
          this.errorMessage = this.translateService.instant('COMMON.DEFAULT_PAYMENT_METHOD_NOT_AVAILABLE_ERROR');
        } else if (
          (selectedAccount && !!selectedAccount.disable_reason) ||
          userProfile?.data?.linked_fi_info.some((fi) => fi.fi_id === consentGrantPayload.selected_fi_id)
        ) {
          // default not available or default disabled or not in linked fis list
          this.errorMessage = this.translateService.instant('COMMON.DEFAULT_PAYMENT_METHOD_NOT_AVAILABLE_ERROR');
        }
      });

    this.oidcDetails$
      .pipe(takeUntil(this.destroy$), debounceTime(300), withLatestFrom(this.store.select(selectOIDCCode)))
      .subscribe(([oidcDetail, oidcCode]) => {
        if (oidcDetail && !this.isGuestCheckout && oidcCode === this.fiAlreadyLinkedCode) {
          this.errorKey = oidcDetail.noticeTranslateKey;
          this.isMobile ? this.openMobileMenu() : this.openDesktopMenu();
        }
      });
  }

  updatePaymentMethod(selectedAccountString: string) {
    const selectedAccount: SelectedAccount = JSON.parse(selectedAccountString);
    this.accounts$
      .pipe(withLatestFrom(this.store.select(selectConsent), this.store.select(selectUserProfile)), take(1))
      .subscribe(([userAccounts, consent, userProfile]) => {
        this.store.dispatch(
          updateConsentGrant({
            consentGrantPayload: {
              fi_account_ref: selectedAccount.selectedAccountId,
              selected_fi_id: selectedAccount.selectedFiId,
              fi_user_id: selectedAccount.selectedFiUserId,
            },
          })
        );
      });
  }

  setDefaultPaymentMethod(paymentInfo: any) {
    this.store.dispatch(
      UserActions.setDefaultPaymentMethodStart({
        linkedFiRef: paymentInfo.linkedFiRef,
        paymentMethodRef: paymentInfo.paymentMethodRef,
      })
    );
  }

  openMobileMenu(): void {
    const btmSheetRef = this._bottomSheet.open(MenuMobileComponent, {
      data: {
        options: [
          {
            action: 'COMMON.BUTTON.LINK_ANOTHER_BANK',
            confirmTitle: 'CHECKOUT.LINK_BANK.FAILURE_TITLE',
            confirmSubtitle: 'CHECKOUT.LINK_BANK.FAILURE_SUBTITLE',
            confirmCancel: 'COMMON.BUTTON.CLOSE',
          },
        ],
        showConfirm: true,
        isErrorInline: false,
        errorKey: this.errorKey,
        currentDisplay: 'COMMON.BUTTON.LINK_ANOTHER_BANK',
      },
      panelClass: 'content_main-drawer',
      backdropClass: 'content_main-bottom-sheet',
    });
    btmSheetRef
      .afterDismissed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.store.dispatch(
          setOIDCCode({
            OIDCCode: undefined,
          })
        );
      });
  }

  openDesktopMenu(): void {
    const dialogRef = this.dialog.open(MenuDesktopConfirmComponent, {
      data: {
        option: {
          action: 'COMMON.BUTTON.LINK_ANOTHER_BANK',
          confirmTitle: 'CHECKOUT.LINK_BANK.FAILURE_TITLE',
          confirmSubtitle: 'CHECKOUT.LINK_BANK.FAILURE_SUBTITLE',
          confirmCancel: 'COMMON.BUTTON.CLOSE',
        },
        isErrorInline: false,
        errorKey: this.errorKey,
      },
      closeOnNavigation: true,
      autoFocus: false,
      width: '416px',
      panelClass: 'content_overlay-medium',
      backdropClass: 'content_overlay-dialog',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(
        setOIDCCode({
          OIDCCode: undefined,
        })
      );
    });
  }
}
