import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { PaymentMethodClass } from '@checkout/shared/classes/payment-method.class';
import { SignOutDirective } from '@core/directives/sign-out.directive';
import { RouterService } from '@core/services/router.service';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LinkBankButtonComponent } from '../../../../../core/components/link-bank-button/link-bank-button.component';
import { PbbNoticeComponent } from '../../../../../core/components/pbb-notice/pbb-notice.component';
import { UserAccountSelectorComponent } from '../../../../../core/components/user-account-selector/user-account-selector.component';
import { UtilService } from '@core/services/util.service';
import { PbbSvgIconComponent } from '@core/components';
import { LinkBankComponent } from '@core/components/link-bank/link-bank.component';

@Component({
  selector: 'pbb-payment-method-page',
  templateUrl: './payment-method-page.component.html',
  styleUrls: ['./payment-method-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LetDirective,
    NgIf,
    LinkBankButtonComponent,
    LinkBankComponent,
    MatButtonModule,
    RouterLink,
    MatIconModule,
    PbbNoticeComponent,
    UserAccountSelectorComponent,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    SignOutDirective,
    PbbSvgIconComponent,
  ],
})
export class PaymentMethodPageComponent extends PaymentMethodClass implements OnInit {
  constructor(
    store: Store,
    private readonly route: ActivatedRoute,
    public routerService: RouterService,
    private readonly router: Router,
    translateService: TranslateService,
    _bottomSheet: MatBottomSheet,
    dialog: MatDialog,
    utilService: UtilService
  ) {
    super(store, translateService, _bottomSheet, dialog, utilService);
  }

  ngOnInit(): void {
    const stepData = this.route.snapshot.data['stepData'] || {};
    this.isGuestCheckout = stepData['isGuestCheckout'] || false;
    this.continueToReview = stepData['continueToReview'] || false;
    this.isReturningUser = stepData['isRegistered'] || false;

    if (this.router.url) {
      this.isOrderReview = this.router.url.indexOf('/orderReview') > 0;
    }
    if (stepData['onPopState']) {
      history.pushState(null, 'null', location.href);
      this.routerService.enableBack = !stepData['onPopState'];
    }
  }

  onClickContinue() {
    this.continueToReview
      ? this.router.navigate(['..'], { relativeTo: this.route })
      : this.router.navigate(['../address'], { relativeTo: this.route });
  }
}
