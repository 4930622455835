<div class="content_main-container" *ngrxLet="userProfileRef$ as userProfileRef">
  <div class="mb-40 flex-row flex-jc-none">
    <div class="link-another-bank-header">
      <button
        mat-icon-button
        class="wallet-back-btn"
        [attr.aria-label]="'COMMON.BUTTON.DIALOG.BACK' | translate"
        id="header_back-btn"
        routerLink=".."
      >
        <mat-icon>arrow_back_outlined</mat-icon>
      </button>

      <h1 class="dialog-title mb-8">{{ 'COMMON.DIALOG.FIND_YOUR_BANK' | translate }}</h1>

      <small id="link-bank-subtitle" class="mat-body-2 block">
        {{ 'COMMON.DIALOG.FIND_YOUR_BANK.SUBTITLE' | translate }}
      </small>
    </div>
  </div>
  <ng-container *ngrxLet="financialInstitutions$ as financialInstitutions">
    <pbb-fi-selection
      id="main_checkout_fi-list"
      (fiSelected)="fiSelected($event, userProfileRef)"
      [financialInstitutions]="financialInstitutions"
    ></pbb-fi-selection>
  </ng-container>
</div>
